<template lang='pug'>
include ../../../../configs/template
form.row
  div.col-12
    +select-validation('body.additional_info.type_document', 'listTypeDocument', 'typeDoc', 'nameLang', '["required"]')(
      @input="(id) => getData(id)" :readonly="isCheckDocument"
    )
  div(v-if="isCheckDocument").col-12
    +field-validation('body.additional_info.registry_number', 'registrationNumber', '["required"]')
  div.col-12
    +field-validation('body.additional_info.serial', 'serial', '["required"]')(:readonly="isCheckDocument")
  div.col-12
    +field-validation('body.additional_info.number_document', 'number', '["required", "maxLength"]')(:readonly="isCheckDocument")
  h5.py-3 {{ $t('infoAboutSpecialization') }}:
  div(v-if="isViewDiploma.includes(body.additional_info.type_document)").col-12
    +select-validation('body.additional_info.extent', 'listExtent', 'educationExtent', 'nameLang', '["required"]')(:readonly="isCheckDocument")
  div.col-12
    +select-validation('body.additional_info.name_nz', 'listInstitution', 'nameInstitution', 'nameLang', '["required"]')(:readonly="isCheckDocument")
  div.col-12
    +select-validation('body.additional_info.qualification', 'listQualification', 'qualification', 'nameLang', '["required"]')(:readonly="isCheckDocument")
  div(v-if="body.additional_info.type_document === TYPE_DOCUMENT_NZ.DIPLOMA_OF_HIGHER_EDUCATION").col-12
    +select-validation('body.additional_info.speciality', 'listProfession', 'specialty', 'nameLang', '["required"]')(
      @input="(id) => listSpecialization = specializationById(id)" :readonly="isCheckDocument"
    )
  div(v-if="body.additional_info.type_document === TYPE_DOCUMENT_NZ.DIPLOMA_OF_QUALIFIED_WORKER").col-12
    +select-validation('body.additional_info.speciality', 'listProfession', 'profession', 'nameLang', '["required"]')(
      @input="(id) => listSpecialization = specializationById(id)" :readonly="isCheckDocument"
    )
  div(v-if="isViewDiploma.includes(body.additional_info.type_document) && isViewSpecialization.includes(body.additional_info.type_document)").col-12
    +select-validation('body.additional_info.specialization', 'listSpecialization', 'specialization', 'nameLang', '["required"]')(:readonly="isCheckDocument")
  div.col-12
    +date-picker-validation('body.additional_info.date_issue_document','dateIssue','dateObjectIsssued','["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(
      :readonly="isCheckDocument")
  div(v-if="isCheckDocument && isViewYearEnd.includes(body.additional_info.type_document)").col-12
    +field-validation('body.additional_info.date_end_educ_year','yearEducationEnd','["required", "minValue", "maxValue"]')(v-mask="'####'")
  div(v-if="isViewDateEnd.includes(body.additional_info.type_document)").col-12
    +date-picker-validation('body.additional_info.date_end_educ','dateEndEducation','dateEndObject','["required", "minValue"]', 'isCheckDocument', '!isCheckDocument')(
      :readonly="isCheckDocument")
  div(v-if="isCheckDocument").col-12
    +field('body.additional_info.special_notes','"notes"')
  div(v-if="isCheckDocument").col-12
    +checkbox('body.additional_info.is_duplicate', 'duplicate')
  div(v-if="isCheckDocument").col-12
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(v-if="isCheckDocument" color="success" @click="saveDocument" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(v-else color="success" @click="checkDocument" :loading="isLoading") {{$t('btn.checkDocument')}}
    v-btn(v-if="isCheckDocument" color="secondary" @click="clearForm" :loading="isLoading") {{$t('btn.clear')}}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { verificationSC } from '@/mixins/validationRules'
import { mappingProfession, clearBody } from '@/mixins/main'
import { TYPE_DOCUMENT_NZ, TYPE_QUALIFICATION_NZ } from '@/configs/constants'

const initBody = () => (
  {
    additional_info: {
      type_document: null,
      number_document: null,
      name_nz: null,
      qualification: null,
      is_duplicate: false,
      experied_date: null,
      date_issue_document: null,
      special_notes: null,
      status_document: null,
      serial: null,
      registry_number: null,
      extent: null,
      speciality: null,
      specialization: null,
      date_end_educ: null,
      date_end_educ_year: null
    }
  }
)
export default {
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: initBody(),
      TYPE_DOCUMENT_NZ,
      mappingProfession,
      listQualification: [],
      listProfession: [],
      listSpecialization: [],
      isCheckDocument: false,
      isViewDiploma: [TYPE_DOCUMENT_NZ.DIPLOMA_OF_HIGHER_EDUCATION],
      isViewProfession: [
        TYPE_DOCUMENT_NZ.DIPLOMA_OF_HIGHER_EDUCATION,
        TYPE_DOCUMENT_NZ.DIPLOMA_OF_QUALIFIED_WORKER
      ],
      isViewDateEnd: [
        TYPE_DOCUMENT_NZ.CERTIFICATE_OF_IMPROVING_QUALIFICATION,
        TYPE_DOCUMENT_NZ.CERTIFICATE_OF_AWARDING_IMPROVING_OF_TRADE_QUALIFICATION
      ],
      isViewYearEnd: [TYPE_DOCUMENT_NZ.DIPLOMA_OF_QUALIFIED_WORKER, TYPE_DOCUMENT_NZ.DIPLOMA_OF_HIGHER_EDUCATION],
      isViewSpecialization: [TYPE_DOCUMENT_NZ.DIPLOMA_OF_HIGHER_EDUCATION],
      isLoading: false
    }
  },
  validations () { return verificationSC.education(this) },
  computed: {
    ...mapGetters(['qualificationById', 'professionById', 'specializationById']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listTypeDocument: state => state.directory.typeDoc,
      listExtent: state => state.directory.extent,
      listInstitution: state => state.directory.institution,
      // listProfession: state => state.directory.profession,
      isLoadingSave: state => state.documents.isLoading
    }),
    dateObjectIsssued () {
      return this.body.additional_info.date_issue_document ? new Date(this.body.additional_info.date_issue_document) : null
    },
    dateEndObject () {
      return this.body.additional_info.date_end_educ ? new Date(this.body.additional_info.date_end_educ) : null
    }
  },
  methods: {
    ...mapActions(['getInfoAboutDocumentForDigitizationSC']),
    getData (typeDocument) {
      let typeNZ = TYPE_QUALIFICATION_NZ.find(el => el.TYPE_DOCUMENT.includes(typeDocument)).TYPE_NZ
      this.listQualification = this.qualificationById(typeNZ)
      this.listProfession = mappingProfession(typeDocument)
    },
    saveDocument () {
      const { statementId } = this.$route.params
      if (this.$v.$invalid) return this.$v.$touch()
      let data = {
        body: { ...this.body },
        media: {
          name: 'GraduationDoc',
          files: [...this.$refs.mediaContent.filesArray]
        }
      }
      if (this.isViewYearEnd.includes(data.body.additional_info.type_document)) {
        data.body.additional_info.date_end_educ = this.body.additional_info.date_end_educ_year + '-01-01'
        delete data.body.additional_info.date_end_educ_year
      }
      this.$emit('saveDocument', { id: statementId, data, method: 'setDigitizationDocumentSC' })
    },
    async checkDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      const { statementId } = this.$route.params
      const response = await this.getInfoAboutDocumentForDigitizationSC({ id: statementId, body: { additional_info: { ...clearBody(this.body.additional_info) } } })
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading = false
    },
    clearForm () {
      this.isCheckDocument = false
      this.body = initBody()
      this.$v.$reset()
    }
  }
}
</script>
